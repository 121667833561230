import { useEffect } from "react";
import "./AboutPage.css"
import Footer from "./Footer";
import MenuBar from "./MenuBar";
import DocumentMeta from "react-document-meta";
function AboutPage()
{
  useEffect(() => {
    document.title = "Verif.ai - About";
  }, []);
  const meta = {
    title: "Verif.ai About",
    description: "About Verif.ai project",
    canonical: "https://verifai-project.com/about",
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'react,meta,document,html,tags'
        }
    }
  }

    return (
      <div className="PageBackground">
          <DocumentMeta {...meta} />
        <div className="AboutPageContainer">
           <div className="MenuBarDiv">
                <MenuBar/>
               </div>
            
            
               <div className="ContentContainer">
                 <div className="TextContainer"> 
                    <h1 className="PageHeading">About</h1>
                      <p>VerifAI is an open-source biomedical generative question-answering engine. However, on top of being able to answer biomedical questions based on indexed scientific publication, it will also reference relevant documents, and verify answers whether claims made in them are fully based on the referenced documents and detect any hallucinations produced by underlaying large language model (our method is based on fine-tuned Mistral 7B).
                    </p>
                    <p>VerifAI is an AI system designed to verify and document the correctness of AI-generated texts. In the core of the engine is generative search engine, powered by open technologies. However, generative models may halucinate, and therefore Verif.ai is developing a second model that would check the sources of generative model and flag any misinformation or misinterpretations of source documents. Therefore, make the answer created by generative search engine completly verifiable. The best part is, that we are making it open source, so anyone can use it!</p>
                    
                    <h1 className="PageHeading">Funding</h1>
                      <p>VerifAI is NGI Search project funded by NGI Search scheme of the European Union under grant agreement No 101069364.
                    </p>

                    <h1 className="PageHeading">Commercial use and consulting</h1>
                      <p>In case, you would like us to consult on deploying solution based on Verif.ai or would like to use the developed solution under different licensing terms then provided by default, please get in touch at <targe><span style={{ color: '#005d97', cursor: 'pointer' }}>verif.ai.project@gmail.com </span></targe>
                    </p>
                   
                   
                    
                    </div>
                </div>

                <div className="FooterDiv">
                    <Footer/>
                </div>
                

                
           
        </div>
        </div>
    )
}

export default AboutPage;