import './MenuBar.css';
import { Link } from 'react-router-dom';
import header_image from './header_image.png';
import { useEffect, useRef, useState } from 'react';
import burger from './burger.svg';

function isRouteActive(path)
{
    
    return window.location.pathname === path;
    
}



function MenuBar()
{

    const menuBarRef = useRef(null);
    const menuBarSection = useRef(null);
    const burgerBarSection = useRef(null);
    const tryAppContainer = useRef(null);
    const [offsetTop, setOffsetTop] = useState(0);
    const [openBurger, setOpenBurger] = useState(false);
  

    const toggleBurger = () => {

       setOpenBurger(prevState => !prevState);

      

    }

   
    useEffect(() => {
        // Ensure that the menuBarRef.current is available
        if (menuBarRef.current && menuBarSection.current && tryAppContainer.current) {
            setOffsetTop(menuBarRef.current.offsetHeight - menuBarSection.current.offsetHeight - tryAppContainer.current.offsetHeight);
            
        }
        

        if (menuBarRef.current && burgerBarSection.current && tryAppContainer.current) {
            setOffsetTop(menuBarRef.current.offsetHeight - burgerBarSection.current.offsetHeight - tryAppContainer.current.offsetHeight);
            
        }

        
        
        const handleScroll = () => {
            
            const fixedMenubar = document.getElementById("fixedMenubar");
            const fixedBurgerBar = document.getElementById("fixedBurgerBar");
            if (fixedMenubar) {
                if (window.scrollY > offsetTop) {
                    fixedMenubar.classList.add('fixed-menubar');
                    
                } else {
                    fixedMenubar.classList.remove('fixed-menubar');
                    
                }
            }

            if (fixedBurgerBar) {
                if (window.scrollY > offsetTop) {
                    fixedBurgerBar.classList.add('fixed-burgerbar');
                    
                } else {
                    fixedBurgerBar.classList.remove('fixed-burgerbar');
                    
                }
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener
        return () => {
          
            window.removeEventListener('scroll', handleScroll);
        };
    }, [offsetTop]); 
    return (
        <div>
           <div className="MenuBarContainer" ref = {menuBarRef}>
            <div className='HeaderContainer'>
                <img className="HeaderImage" src={header_image}/>
            </div>
                <Link className='DivLink'  to="https://app.verifai-project.com/registration"  target="_blank" rel="noopener noreferrer"><div className="TryAppContainer" ref={tryAppContainer}>
                    <h1 className="tryAppMessage">TRY VerifAI APP NOW</h1>
                </div></Link>

              
                <div className="MenuBar" id="fixedMenubar" ref={menuBarSection}>
                   <Link className='ButtonLink' to="/"> <button className={`MenuButton ${isRouteActive('/') ? 'focused' : ''}`}>Home</button></Link>
                   <Link className='ButtonLink' to="/about"> <button className={`MenuButton ${isRouteActive('/about') ? 'focused' : ''}`}>About</button></Link>
                   <Link className='ButtonLink' to="https://app.verifai-project.com/login" target="_blank" rel="noopener noreferrer"> <button className={`MenuButton ${isRouteActive('/sign_in') ? 'focused' : ''}`}>Sign in</button></Link>
                   <Link className='ButtonLink' to="/publications"> <button className={`MenuButton ${isRouteActive('/publications') ? 'focused' : ''}`}>Publications</button></Link>
                   <Link className='ButtonLink' to="/team"> <button className={`MenuButton ${isRouteActive('/team') ? 'focused' : ''}`}>Team</button></Link>
                   <Link className='ButtonLink' to="/blog"> <button className={`MenuButton ${isRouteActive('/blog') || isRouteActive('/post') ? 'focused' : ''}`}>Blog</button></Link>
                   <Link className='ButtonLink' to="/q&a"> <button className={`MenuButton ${isRouteActive('/q&a') ? 'focused' : ''}`}>Q&A</button></Link>  
                   <Link className='ButtonLink' to="/contact"> <button className={`MenuButton ${isRouteActive('/contact') ? 'focused' : ''}`}>Contact</button></Link>
                    
                </div>
             

                <div className="BurgerBar" ref={burgerBarSection} id="fixedBurgerBar">
                    <img className='burgerButton' src={burger} onClick={() => toggleBurger()}/>

                </div>

                {openBurger && (
                <div className={`BurgerMenuBar ${openBurger || 'fade-out'}`}>
                   <Link className='ButtonLink' to="/"> <button className={`MenuButton ${isRouteActive('/') ? 'focused' : ''}`}>Home</button></Link>
                   <Link className='ButtonLink' to="/about"> <button className={`MenuButton ${isRouteActive('/about') ? 'focused' : ''}`}>About</button></Link>
                   <Link className='ButtonLink' to="https://app.verifai-project.com/login" target="_blank" rel="noopener noreferrer"> <button className={`MenuButton ${isRouteActive('/sign_in') ? 'focused' : ''}`}>Sign in</button></Link>
                   <Link className='ButtonLink' to="/publications"> <button className={`MenuButton ${isRouteActive('/publications') ? 'focused' : ''}`}>Publications</button></Link>
                   <Link className='ButtonLink' to="/team"> <button className={`MenuButton ${isRouteActive('/team') ? 'focused' : ''}`}>Team</button></Link>
                   <Link className='ButtonLink' to="/blog"> <button className={`MenuButton ${isRouteActive('/blog') || isRouteActive('/post') ? 'focused' : ''}`}>Blog</button></Link>
                   <Link className='ButtonLink' to="/faq"> <button className={`MenuButton ${isRouteActive('/faq') ? 'focused' : ''}`}>Q&A</button></Link>  
                   <Link className='ButtonLink' to="/contact"> <button className={`MenuButton ${isRouteActive('/contact') ? 'focused' : ''}`}>Contact</button></Link>
                    
                </div>
                   )}
                </div>
        </div>
    )
}

export default MenuBar;