import NikolaMilosevic from './Nikola-Milosevic.png';
import AdelaLjajic from './adelaljajic.jpg';
import MilosKosprdic from './miloskosprdic.jpg';
import BojanaBasaragin from './bojanabasaragin.jpg';
import DarijaMedvecki from './Darija-Medvecki_manja-eng.jpg';
import Footer from "./Footer";
import MenuBar from "./MenuBar";
import linkedin from "./linkedin3.svg"
import './MemberPage.css';
import { Link } from 'react-router-dom';


/*const teamMembers = [
    { id: "nikola-milosevic", linkedin: "https://www.linkedin.com/in/nikolamilosevic1986/", name: "Nikola Milošević", status: "Team lead", desc: "The initiator and a team lead in the VerifAI project, currently employed at Bayer A.G; Senior computational scientist with deep expertise in natural language processing and machine learning...", image: NikolaMilosevic, longDesc: "Nikola Milosevic is a senior computational scientist with deep expertise in natural language processing and machine learning. He holds a PhD in Computer Science from the University of Manchester, where he focused on information extraction and mining from tables in biomedical and clinical scientific literature. With extensive experience in both academia and industry, Milosevic has worked on various projects involving web crawling, machine learning, and advanced NLP techniques, such as information extraction, text classification, text summarization, and lately is very involved in generative AI. He is also interested in NLP for low-resourced languages such as Serbian, or applying NLP techniques in other fields, such as biomedicine, or malware analysis. Nikola Milosevic is currently employed at Bayer A.G., as a computational scientist. He is the initiator and a team lead in the VerifAI project. Nikola has a long history of open-source involvement, dating back to 2012, when he became involved with the Open Web Application Security Project, but he also open-sourced the ority of his scientific research. "},
    { id: "adela-ljajic", linkedin: "https://www.linkedin.com/in/adelacrnisanin/?originalSubdomain=rs", name: "Adela Ljajić", status: "Team member", desc: "Description text", image: AdelaLjajic},
    { id: "milos-kosprdic", linkedin: "https://www.linkedin.com/in/milo%C5%A1-ko%C5%A1prdi%C4%87/?originalSubdomain=rs",  name: "Miloš Košprdić", status: "Team member", desc: "Researcher at The Institute for Artificial Intelligence of Serbia; Senior Associate at Linguistics Department at Petnica Science Center...", image: MilosKosprdic, longDesc: "Researcher at The Institute for Artificial Intelligence of Serbia; Senior Associate at Linguistics Department at Petnica Science Center..."},
    { id: "bojana-basaragin", linkedin: "https://www.linkedin.com/in/bojana-basaragin/?originalSubdomain=rs", name: "Bojana Bašaragin", status: "Team member", desc: "Senior Researcher in the Human-Computer Interaction research group at The Institute for Artificial Intelligence of Serbia; Leverages language technologies in healthcare and industry, with a particular focus on language models...", image: BojanaBasaragin, longDesc: "Bojana Bašaragin earned her Ph.D. in Computational Linguistics from the Faculty of Philology in Belgrade, specializing in syntax and natural language processing. Her significant contributions in this field include the creation and enhancement of language resources for Serbian, encompassing WordNet, a dictionary of synonyms, parallel corpora, rule-based parsers, and formal grammars. At the Institute, she serves as a Senior Researcher in the Human-Computer Interaction research group. In her projects, she leverages language technologies in healthcare and industry, with a particular focus on language models. Driven by a belief in the transformative power of AI, she is passionate about applying natural language processing techniques in healthcare to improve lives and advance biomedical research."},
    { id: "darija-medvecki", linkedin: "https://www.linkedin.com/in/darija-medvecki-b27468ab/?originalSubdomain=rs", name: "Darija Medvecki", status: "Team member", desc: "Research assistant at the Human-Computer Interaction research group at The Institute for Artificial Intelligence of Serbia; Specializing in the field of natural language processing and a marketing specialist...", image: DarijaMedvecki, longDesc: "Darija Medvecki is a young researcher specializing in the field of natural language processing and a marketing specialist. She is currently pursuing a Ph.D. in artificial intelligence at the Faculty of Technical Sciences in Novi Sad. As a research assistant at the Human-Computer Interaction research group at the Institute, she contributes to multiple projects across diverse fields, including the biomedical domain, customer service, and social media. Her work encompasses sentiment analysis, topic modeling, and named entity recognition, as well as contributing to the development of Serbian language resources. Her primary focus is on data collection, preparation, and leveraging large language models."},
];*/

const teamMembers = [
    { id: "nikola-milosevic", linkedin: "https://www.linkedin.com/in/nikolamilosevic1986/", name: "Nikola Milošević", status: "Team lead", desc: "The initiator and a team lead in the VerifAI project, currently employed at Bayer A.G; Senior computational scientist with deep expertise in natural language processing and machine learning...", image: NikolaMilosevic, longDesc: "Nikola Milosevic is a senior computational scientist with deep expertise in natural language processing and machine learning. He holds a PhD in Computer Science from the University of Manchester, where he focused on information extraction and mining from tables in biomedical and clinical scientific literature. With extensive experience in both academia and industry, Milosevic has worked on various projects involving web crawling, machine learning, and advanced NLP techniques, such as information extraction, text classification, text summarization, and lately is very involved in generative AI. He is also interested in NLP for low-resourced languages such as Serbian, or applying NLP techniques in other fields, such as biomedicine, or malware analysis. Nikola Milosevic is currently employed at Bayer A.G., as a computational scientist. He is the initiator and a team lead in the VerifAI project. Nikola has a long history of open-source involvement, dating back to 2012, when he became involved with the Open Web Application Security Project, but he also open-sourced the ority of his scientific research. "},
    { id: "bojana-basaragin", linkedin: "https://www.linkedin.com/in/bojana-basaragin/?originalSubdomain=rs", name: "Bojana Bašaragin", status: "Team member", desc: "Senior Researcher in the Human-Computer Interaction research group at The Institute for Artificial Intelligence of Serbia; Leverages language technologies in healthcare and industry, with a particular focus on language models...", image: BojanaBasaragin, longDesc: "Bojana Bašaragin earned her Ph.D. in Computational Linguistics from the Faculty of Philology in Belgrade, specializing in syntax and natural language processing. Her significant contributions in this field include the creation and enhancement of language resources for Serbian, encompassing WordNet, a dictionary of synonyms, parallel corpora, rule-based parsers, and formal grammars. At the Institute, she serves as a Senior Researcher in the Human-Computer Interaction research group. In her projects, she leverages language technologies in healthcare and industry, with a particular focus on language models. Driven by a belief in the transformative power of AI, she is passionate about applying natural language processing techniques in healthcare to improve lives and advance biomedical research."},
    { id: "darija-medvecki", linkedin: "https://www.linkedin.com/in/darija-medvecki-b27468ab/?originalSubdomain=rs", name: "Darija Medvecki", status: "Team member", desc: "Research assistant at the Human-Computer Interaction research group at The Institute for Artificial Intelligence of Serbia; Specializing in the field of natural language processing and a marketing specialist...", image: DarijaMedvecki, longDesc: "Darija Medvecki is a young researcher specializing in the field of natural language processing and a marketing specialist. She is currently pursuing a Ph.D. in artificial intelligence at the Faculty of Technical Sciences in Novi Sad. As a research assistant at the Human-Computer Interaction research group at the Institute, she contributes to multiple projects across diverse fields, including the biomedical domain, customer service, and social media. Her work encompasses sentiment analysis, topic modeling, and named entity recognition, as well as contributing to the development of Serbian language resources. Her primary focus is on data collection, preparation, and leveraging large language models."},
];

function MemberPage(){

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const index = urlParams.get('id');
    var member = teamMembers.find(x => x.id === index)

    return(
        <div className="PageBackground">
            <div className="MemberPageContainer">
                <div className="MenuBarDiv">
                    <MenuBar/>
                </div>
                <div className="MemberPageContent">
                    <div className='MemberPageDiv'>
                    <div className="GoBackDiv"> 
                         <div className='memberLinkedIn'><h1>{member.name}</h1><Link to={member.linkedin} target="_blank" rel="noopener noreferrer"><button className='LinkedInButton'><img src={linkedin}/></button></Link></div> 
                        <Link to="/team" className="GoBackLink"><h1>Team page</h1></Link></div>
                  
                    <h2>{member.status}</h2>

                    <div className='MemberLayout'>
                  
                    <p>{member.longDesc}</p>
                    <img src={member.image}/>
                    </div>
                    </div>
                </div>

                <div className="FooterDiv">
                    <Footer/>
                </div>
            </div>
        </div>
    )

}
export default MemberPage;